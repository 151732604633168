import React, { useState } from "react";
import styled from "styled-components";
import Spinner from "../spinner";
import { supabase } from "../../supabase";

const AiSearch = ({ searchQuery, onChange, onNewResults, placeholder }) => {
  const [isLoading, setLoading] = useState(false);

  const askAI = async () => {
    setLoading(true);

    // Track the search query, unless localhost
    if (window.location.hostname !== "localhost") {
      window.sa_event("ai_search", {
        searchQuery,
      });
    }

    const res = await supabase.functions.invoke("similarity-search", {
      body: { userInput: searchQuery },
    });

    const { data, error } = res;

    if (error) {
      setLoading(false);

      console.error(error);
      alert(
        "Ooops, something went wrong. This is a beta feature and may not work as expected."
      );
      return onNewResults([]);
    }

    const { embedding } = data;

    let { data: documents, error: matchError } = await supabase.rpc(
      "match_documents",
      {
        match_count: 10, // Choose the number of matches
        match_threshold: 0.78, // Choose an appropriate threshold for your data
        query_embedding: embedding,
      }
    );
    if (matchError) {
      // console.error(documents);
      setLoading(false);

      alert(
        "Ooops, something went wrong. This is a beta feature and may not work as expected yet. Sorry for that!"
      );

      return onNewResults([]);
    } else {
      // console.log(documents);
      setLoading(false);
      return onNewResults(documents);
    }
  };

  return (
    <Row>
      <textarea
        onChange={(ev) => onChange(ev.target.value)}
        placeholder={placeholder || "Sale of used cars"}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            askAI(searchQuery);

            // unfocus
            ev.target.blur();

            // prevent making a new line
            ev.preventDefault();
          }
        }}
      />
      <AIButton
        disabled={searchQuery.length < 3 || isLoading}
        onClick={() => askAI(searchQuery)}
      >
        {isLoading ? (
          <Spinner small>
            <span className="loader small"></span>
          </Spinner>
        ) : (
          "Search ✨"
        )}
      </AIButton>
    </Row>
  );
};

export default AiSearch;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const AIButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  background: var(--primary);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: var(--primary-dark);
  }
  &:disabled {
    background: #6e6e6e;
    cursor: default;

    &:hover {
      background: #6e6e6e;
    }
  }
`;
