import React, { useEffect, useState } from "react";
import { supabase } from "../supabase";
import { useNavigate } from "react-router";
import _ from "lodash";
import sicList from "../data/sicList";
import CompanyCard from "../components/companyCard";
import { CompanyList } from "../App";
import styled from "styled-components";
import moment from "moment";
import useUserStore from "../stores/userStore";
import { isMember } from "../helpers/userHelpers";
import Pagination from "../components/pagination/pagination";
import Spinner from "../components/spinner";
import {
  Bell,
  Check,
  Download,
  Info,
  X,
} from "feather-icons-react/build/IconComponents";
import Modal from "react-modal";
import PrimaryButton from "../components/buttons/primaryButton";
import toast, { Toaster } from "react-hot-toast";
import useConfigStore from "../stores/serverConfigStore";
import Helmet from "react-helmet";

const showSuccessSnack = (msg, icon) => toast(msg, { icon, duration: 2000 });

const UpdatingServerMessage = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  max-width: 500px;
  color: #fff;
  margin: 50px auto;
  font-size: 1.2rem;
  background: #635bff;
  padding: 5px 15px;
  border-radius: 5px;

  svg {
    margin-right: 5px;
    vertical-align: middle;
  }

  p {
    margin: 0;
    padding: 5px;
  }
`;

const MessageTitle = styled.p`
  font-weight: bold;
  font-size: 1rem;

  .small {
    font-size: 0.8rem;
    color: #ccc;
    font-weight: normal;
  }
`;

const MessageBody = styled.p`
  font-size: 1rem;
`;

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",

    // mobile
    width: "90%",
    maxWidth: "400px",
  },

  overlay: {
    backgroundColor: "#595757bf",
  },
};

Modal.setAppElement("#root");

const Container = styled.div`
  width: 800px;
  margin: 0 auto;
  padding: 1rem;

  // mobile

  @media (max-width: 600px) {
    width: 90%;
    padding: 0.5rem;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;

  // mobile, space between each control

  @media (max-width: 600px) {
    & > * {
      margin-right: 1rem;
    }
  }
`;

const StyledDropdown = styled.select`
  flex: 1;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
`;

const AccessHintWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  height: 150px;
  border-radius: 5px;
  background: linear-gradient(180deg, #eaf3ff 0%, rgba(255, 255, 255, 0) 100%);
`;

const AccessHint = styled.a`
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  text-decoration: underline !important;
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.5rem;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  // mobile
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: normal;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f1f1f1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  transition: 0.3s ease;

  &:hover {
    background-color: #e1e1e1;
  }

  svg {
    margin-right: 5px;
    transition: 0.3s ease;
  }

  &:disabled {
    background-color: #f1f1f1;
    color: #999;
    cursor: not-allowed;
  }

  // success button
  ${(props) =>
    props.success &&
    `
    opacity: 0.8;
    border: none;
    background-color: transparent;
    &:hover {
      background-color: transparent;
      opacity: 1;
      svg {
        // fill: gold;
        stroke: 1px solid #cecece;
      }
    }
  `}
  ${(props) =>
    props.success &&
    props.activated &&
    `
    background-color: transparent;
    svg {
      fill: gold;
      stroke: 1px solid #cecece;
    }
  `}

  // subscribing
  ${(props) =>
    props.issubscribing &&
    `
    svg {
      transform: scale(0.9);
    }
  `}

  // disabled
  ${(props) =>
    props.disabled &&
    `
    background-color: #f1f1f1;
    color: #999;
    cursor: not-allowed;

    &:hover {
      background-color: #f1f1f1;
    }
  `}

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 0.5rem;
    justify-content: center;
  }
`;

const twoMonthsAgo = moment().subtract(2, "months");
// console.log(prevMonth);

const BySicCode = () => {
  const [companies, setCompanies] = useState(null);
  const [fullSicCode, setFullSicCode] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [registrationYear, setRegistrationYear] = useState(twoMonthsAgo.year());
  const [registrationMonth, setRegistrationMonth] = useState(
    twoMonthsAgo.month() + 1
  );
  const [city, setCity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [totalNumOfCompanies, setTotalNumOfCompanies] = useState(0);

  const [isSubscribing, setIsSubscribing] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  // const [isSubscribing, setIsSubscribing] = useState(false);

  const { user, userFilterSubscriptions, setUserFilterSubscriptions } =
    useUserStore();

  const { configs } = useConfigStore();

  const updatingConfig = configs.find(
    (config) =>
      config.config_name === "MAINTENANCE_MODE" && config.active === true
  );

  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanies();

    if (user && userFilterSubscriptions === null) {
      const fetchSubs = async () => {
        const res = await supabase
          .from("user_companies_watch")
          .select("*")
          .eq("user_id", user.uid);

        const data = res.data;
        const error = res.error;

        if (error) {
          console.log(error);
          alert("Something went wrong, please try again later.");
          return;
        }

        setUserFilterSubscriptions(data);
      };

      fetchSubs();
    }

    return () => {
      setCompanies(null);
      setFullSicCode(null);
    };
  }, [registrationYear, registrationMonth, city, currentPage]);

  const fetchCompanies = async () => {
    if (updatingConfig) {
      return;
    }

    setIsLoading(true);
    setErrorMsg(null);
    setCompanies(null);
    setTotalNumOfCompanies(0);
    // Get the sic code from the url
    const url = window.location.href;
    const code = url.split("/").pop();

    // Get the letter from the url
    const slug = url.split("/")[4];
    const letter = slug[0];

    // Get the full sic code from the json file
    const codeList = sicList.find(
      (item) => item.letter === letter.toUpperCase()
    );

    const sicCode = codeList.items.find(
      (item) => item.match(/\d+/)[0] === code
    );

    setFullSicCode(sicCode);

    // supabase DB function
    let data;
    let error;
    let res;

    const maxPerPage = isMember(user) ? 15 : 3;
    const offsetint = (currentPage - 1) * maxPerPage;

    if (city && city !== "Any") {
      res = await supabase.rpc("by_date_sic_city", {
        month: parseInt(registrationMonth),
        year: parseInt(registrationYear),
        sictext1: sicCode,
        city: city?.toUpperCase(),
        offsetint,
      });
    } else {
      res = await supabase.rpc("by_date_and_sic", {
        month: parseInt(registrationMonth),
        year: parseInt(registrationYear),
        sictext1: sicCode,
        offsetint,
      });
    }

    data = res.data;
    error = res.error;

    if (error) {
      console.log(error);
      setErrorMsg("Something went wrong, please try again later.");
      setIsLoading(false);
      return;
    } else if (!data) {
      console.log("No data");
      setIsLoading(false);
      setCompanies([]);
      return;
    } else {
      let formattedData = _.flatten([data]).map((item) => {
        const {
          CompanyName,
          CompanyNumber,
          IncorporationDate,
          PostTown,
          total,
        } = item;

        return {
          CompanyName,
          CompanyNumber,
          IncorporationDate,
          City: PostTown,
          total,
        };
      });

      // Set total pages
      if (formattedData.length === 0) {
        setTotalPages(null);
      } else {
        setTotalPages(Math.ceil(formattedData[0].total / 15));
        setTotalNumOfCompanies(formattedData[0].total);
      }

      // Cap non memebers to 3 results
      if (!isMember(user)) {
        formattedData = formattedData.slice(0, 3);
      }

      setCompanies(formattedData);
      setIsLoading(false);
    }
  };

  // Row with registration date controls
  const renderRegistrationDateControls = () => {
    return (
      <Row>
        <ControlWrapper>
          <label htmlFor="city">City</label>
          <StyledDropdown
            // value={city}
            // allow null
            key={"citySelect"}
            id="city"
            onChange={(e) => {
              setCurrentPage(1);
              setTotalPages(null);
              setCity(e.target.value);
            }}
            value={city ? city : "Any"}
          >
            <option value={null}>Any</option>
            {CITIES.map((country) => (
              <optgroup key={country.country} label={country.country}>
                {country.cities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </optgroup>
            ))}
          </StyledDropdown>
        </ControlWrapper>

        <ControlWrapper>
          <label htmlFor="registrationYear">Year</label>
          <StyledDropdown
            key={"regYearSelect"}
            value={registrationYear.toString()}
            onChange={(e) => {
              setCurrentPage(1);
              setTotalPages(null);

              setRegistrationYear(e.target.value);
            }}
          >
            {_.range(1900, new Date().getFullYear() + 1).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </StyledDropdown>
        </ControlWrapper>

        <ControlWrapper>
          <label htmlFor="registrationMonth">Month</label>
          <StyledDropdown
            key={"regMonthSelect"}
            id="registrationMonth"
            value={registrationMonth}
            onChange={(e) => {
              setCurrentPage(1);
              setTotalPages(null);
              setRegistrationMonth(e.target.value);
            }}
          >
            <option value={null} disabled={!isMember(user)}>
              Any{!isMember(user) && " (members only)"}
            </option>
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </StyledDropdown>
        </ControlWrapper>
      </Row>
    );
  };

  const renderPaginationOrAccessHint = () => {
    if (isMember(user)) {
      return (
        <Pagination
          onPaginate={(num) => setCurrentPage(num)}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      );
    }

    return (
      <AccessHintWrapper>
        <AccessHint
          href="#"
          onClick={() => {
            if (user) {
              navigate("/account");
            } else {
              navigate("/login");
            }
          }}
        >
          Access full list and more
        </AccessHint>
      </AccessHintWrapper>
    );
  };

  const renderCompanies = () => {
    if (updatingConfig) {
      const { message_title, message_body } = updatingConfig.data;
      const todaysDate = moment().format("DD-MM-YYYY");

      return (
        <UpdatingServerMessage>
          <MessageTitle>
            <Info size={30} />
            {message_title} | <span className="small">{todaysDate}</span>
          </MessageTitle>
          <MessageBody>{message_body}</MessageBody>
        </UpdatingServerMessage>
      );
    }

    if (isLoading) {
      return (
        <SpinnerWrapper>
          <Spinner>
            <span className="loader"></span>
          </Spinner>
        </SpinnerWrapper>
      );
    }

    if (errorMsg) return <p>{errorMsg}</p>;

    if (!companies) return null;

    if (companies.length === 0)
      return (
        <EmptyStateWrapper>
          <p>No companies found for selected filter</p>
        </EmptyStateWrapper>
      );

    return (
      <div>
        <CompanyList>
          {companies.map((company, index) => (
            <li key={company.CompanyNumber}>
              <CompanyCard key={index} company={company} />
            </li>
          ))}
        </CompanyList>

        {renderPaginationOrAccessHint()}
      </div>
    );
  };

  const openExportCompaniesModal = () => {
    setModalOpen(true);
  };

  const userIsSubscribed =
    userFilterSubscriptions &&
    userFilterSubscriptions.some((sub) => {
      if (sub.city === null) {
        return sub.sic_text === fullSicCode;
      } else {
        return (
          sub.sic_text === fullSicCode &&
          sub.city?.toUpperCase() === city?.toUpperCase()
        );
      }
    });

  const toggleSubsciption = async () => {
    setIsSubscribing(true);

    if (userIsSubscribed) {
      // remove existing entry in DB

      const activeSub = userFilterSubscriptions.find((sub) => {
        if (city === null) {
          return sub.sic_text === fullSicCode;
        } else {
          return (
            sub.sic_text === fullSicCode &&
            sub.city?.toUpperCase() === city?.toUpperCase()
          );
        }
      });

      const { error } = await supabase
        .from("user_companies_watch")
        .delete()
        .eq("uuid", activeSub.uuid);

      if (error) {
        setIsSubscribing(false);
        showSuccessSnack("Something went wrong, please try again later.", "🤷");
        return;
      }

      setIsSubscribing(false);
      showSuccessSnack("Unsubscribed", "🙅");
    } else {
      // create new entry in the user_companies_watch table
      const { data, error } = await supabase
        .from("user_companies_watch")
        .insert([
          {
            user_id: user.uid,
            sic_text: fullSicCode,
            city: city,
          },
        ]);

      if (error) {
        console.log(error);
        showSuccessSnack("Something went wrong, please try again later.", "🤷");
        setIsSubscribing(false);
        return;
      }
      setIsSubscribing(false);
      showSuccessSnack("Subscribed", "👍");
    }

    // fetch the user's subscriptions
    const res = await supabase
      .from("user_companies_watch")
      .select("*")
      .eq("user_id", user.uid);

    const newSubs = res.data;
    const updateError = res.error;

    if (updateError) {
      alert("Something went wrong, please try again later.");
      return;
    }

    // console.log("DATA", newSubs);

    setUserFilterSubscriptions(newSubs);
  };

  const exportCompanies = async () => {
    const exportFilters = {
      dbFuncName:
        city && city !== "Any" ? "by_date_sic_city" : "by_date_and_sic",
      city: city,
      regMonth: registrationMonth,
      regYear: registrationYear,
      sicText: fullSicCode,
    };

    const { error } = await supabase.functions.invoke("generate-pdf", {
      body: { email: user.email, exportFilters },
    });

    if (error) {
      alert("Something went wrong, please try again later.");
      return;
    }
  };

  return (
    <Container>
      <Helmet>
        <title>{fullSicCode || ""} | LeadsFinder</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <HeaderRow>
        <h2>{fullSicCode || ""}</h2>
      </HeaderRow>

      {renderRegistrationDateControls()}

      <FilterButtonRow>
        <StyledButton
          disabled={!companies || companies.length === 0}
          onClick={() => {
            if (user && !isMember(user)) {
              navigate("/account");
            } else if (!user) {
              navigate("/login");
            } else {
              openExportCompaniesModal();
            }
          }}
        >
          <Download size={20} />
          Export
        </StyledButton>

        <StyledButton
          success={true}
          issubscribing={isSubscribing}
          activated={userIsSubscribed}
          disabled={registrationMonth === null || registrationYear === null}
          onClick={() => {
            if (isMember(user)) {
              toggleSubsciption();
            } else if (user) {
              navigate("/account");
            } else {
              navigate("/login");
            }
          }}
        >
          <Bell size={25} />
          {userIsSubscribed ? "Subscribed" : "Subscribe weekly"}
        </StyledButton>
      </FilterButtonRow>

      {renderCompanies()}

      <Modal
        isOpen={modalOpen}
        // onAfterOpen={() => console.log("Modal opened")}
        // onRequestClose={() => setModalOpen(false)}
        style={customModalStyles}
        contentLabel="Export companies modal"
      >
        <h2>New export</h2>
        <div>
          <p>
            You will export a total of <i>{totalNumOfCompanies} entries</i>. An
            email will be sent to you with a link to download the CSV file.
          </p>
        </div>

        <Row>
          <PrimaryButton onClick={() => setModalOpen(false)}>
            <X size={20} />
            Cancel
          </PrimaryButton>
          <PrimaryButton
            success
            onClick={async () => {
              toast.promise(exportCompanies(), {
                loading: "Exporting...",
                success: () =>
                  "Export successful. An email has been sent to you.",
                error: () => "Something went wrong, please try again later.",
              });
              setModalOpen(false);
            }}
          >
            <Check size={20} />
            Export
          </PrimaryButton>
        </Row>
      </Modal>
      <Toaster />
    </Container>
  );
};

export default BySicCode;

const CITIES = [
  {
    country: "England",
    cities: [
      "Bath",
      "Birmingham",
      "Bradford",
      "Brighton & Hove",
      "Bristol",
      "Cambridge",
      "Canterbury",
      "Carlisle",
      "Chelmsford",
      "Chester",
      "Chichester",
      "Colchester",
      "Coventry",
      "Derby",
      "Doncaster",
      "Durham",
      "Ely",
      "Exeter",
      "Gloucester",
      "Hereford",
      "Kingston-upon-Hull",
      "Lancaster",
      "Leeds",
      "Leicester",
      "Lichfield",
      "Lincoln",
      "Liverpool",
      "London",
      "Manchester",
      "Milton Keynes",
      "Newcastle-upon-Tyne",
      "Norwich",
      "Nottingham",
      "Oxford",
      "Peterborough",
      "Plymouth",
      "Portsmouth",
      "Preston",
      "Ripon",
      "Salford",
      "Salisbury",
      "Sheffield",
      "Southampton",
      "Southend-on-Sea",
      "St Albans",
      "Stoke on Trent",
      "Sunderland",
      "Truro",
      "Wakefield",
      "Wells",
      "Westminster",
      "Winchester",
      "Wolverhampton",
      "Worcester",
      "York",
    ],
  },
  {
    country: "Northern Ireland",
    cities: ["Armagh", "Bangor", "Belfast", "Lisburn", "Londonderry", "Newry"],
  },
  {
    country: "Scotland",
    cities: [
      "Aberdeen",
      "Dundee",
      "Dunfermline",
      "Edinburgh",
      "Glasgow",
      "Inverness",
      "Perth",
      "Stirling",
    ],
  },
  {
    country: "Wales",
    cities: [
      "Bangor",
      "Cardiff",
      "Newport",
      "St Asaph",
      "St Davids",
      "Swansea",
      "Wrexham",
    ],
  },
];

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  font-size: 1.2rem;
  color: #666;
`;

const FilterButtonRow = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 1rem;

  button {
    margin: 0 0.5rem 0 0;
  }

  // mobile
  @media (max-width: 600px) {
    /* flex-direction: column; */
    button {
      margin: 3px;
    }
    align-items: normal;
  }
`;
